// @ts-nocheck
import { Global } from "@mantine/core";
import balooDa2 from "./assets/fonts/baloo-da-2.woff2";
import nowBold from "./assets/fonts/now-bold.woff2";
import nowMedium from "./assets/fonts/now-medium.woff2";
import nowRegular from "./assets/fonts/now-regular.woff2";

export function CustomFonts() {
  return (
    <Global
      styles={[
        {
          "@font-face": {
            fontFamily: "BalooDa2",
            src: `local("BalooDa2"), url("${balooDa2}") format("woff2-variations")`,
            fontWeight: "400 800",
            fontStyle: "normal",
            fontDisplay: "block"
          },
        },
        {
          "@font-face": {
            fontFamily: "Now",
            src: `local("NowBold"), url("${nowBold}") format("woff2")`,
            fontWeight: "bold",
            fontStyle: "normal",
            fontDisplay: "block"
          },
        },
        {
          "@font-face": {
            fontFamily: "Now",
            src: `local("NowMedium"), url("${nowMedium}") format("woff2")`,
            fontWeight: 500,
            fontStyle: "normal",
            fontDisplay: "block"
          },
        },
        {
          "@font-face": {
            fontFamily: "Now",
            src: `local("NowRegular"), url("${nowRegular}") format("woff2")`,
            fontWeight: "normal",
            fontStyle: "normal",
            fontDisplay: "block"
          },
        },
      ]}
    />
  );
}