export const FR_TRANSLATIONS = {
  nav: {
    logoAlt: "Logo Soss",
    aboutUs: "Qui sommes-nous ?",
    ourServices: "Nos services",
    contact: "Contact"
  },
  main: {
    homeHelpAndCare: "Aide et soins à domicile",
    stayingAtHomeIsToRemainOneself: "Rester chez soi,<br>c'est <blueText>rester soi</blueText>",
    ourStory: "Notre histoire",
    ourStoryText: "Je suis le fruit de nombreuses années d’expériences auprès de personnes fragilisées par \
la maladie, la dépendance et l’âge, jonchées de rencontres positives qui m’ont grandi, d’autres moins \
glorieuses qui m’ont appris.<br><br>C’est dans ce contexte que j’ai pris vie, tout d’abord dans un esprit.<br/><br> \
Aujourd’hui je remercie tous ceux qui ont cru en moi et rendu vivant ce qui n’était qu’à l’état de néant.<br><br> \
Je suis né suite à la volonté de ce même esprit de vouloir vivre ses rêves et cesser de rêver sa vie.<br><br> \
A cette volonté d’ajouter de la vie aux années et non des années à tous prix.<br><br>La suite de ce récit, \
c’est ensemble qu’il sera écrit.",
    ourTeam: "Notre équipe",
    ourTeamDescription: "Composée d’une équipe aux disciplines et à l’expertise variées (aides socio-familiales, aide-soignantes, infirmières, infirmières graduées, ergothérapeutes, agent administratif...), notre équipe d’aide et de soins croit profondément aux valeurs qui nous animent et répond aux critères des <blueText>7 C</blueText>​.",
    confidence: "<blueText>C</blueText>onfiance",
    skills: "<blueText>C</blueText>ompétences",
    compassion: "<blueText>C</blueText>ompassion",
    climate: "<blueText>C</blueText>limat",
    congruence: "<blueText>C</blueText>ongruence",
    cohesion: "<blueText>C</blueText>ohesion",
    constance: "<blueText>C</blueText>onstance",
    ourPhilosophy: "Notre philosophie",
    ourPhilosophyDescription: "Approche centrée sur la personne et non sur la maladie et sur les forces et les capacités plutôt que faiblesses des usagers (kitwood) synthétisée au travers de l’acronyme <blueText>VIPS</blueText>.",
    vipsVDescription: "V pour valorisation et reconnaissance des personnes atteintes de pathologies démentielles et des personnes qui prennent soin d’elles.",
    vipsIDescription: "I pour individualisation du prendre soin.",
    vipsPDescription: "P pour perspective de la personne.",
    vipsSDescription: "S pour soutien social et relationnel.",
    ourServices: "Nos services",
    homeSupport: "Soutien à domicile",
    homeCare: "Soins à domicile",
    information: "Information",
    caregiverSupport: "Soutien aux aidants",
    mutualAid: "Entraide",
    homeSupportDescription: "Accompagner avec confiance et humanité.",
    homeCareDescription: "Apporter des soins avec respect en toute sécurité.",
    informationDescription: "Trouver des conseils, être accompagné, recevoir des informations et de l’aide",
    caregiverSupportDescription: "Intégrer des groupes de paroles, échanger avec des pairs, comprendre la maladie, s’entretenir avec un professionnel",
    mutualAidDescription: "Demander de l’aide pour changer un ampoule, entretenir le jardin, se rendre à un rendez-vous, petit service",
    autonomyAssistance: "Aide à l'autonomie",
    accompanyingOuting: "Accompagnement sortie",
    travelAssistance: "Aide au déplacement",
    everydayServices: "Services du quotidien",
    housingMaintenance: "Entretien du logement",
    mealDelivery: "Formalités pour portage de repas",
    nursingCare: "Soins infirmiers",
    kinesitherapy: "Kinésitherapie",
    ergotherapy: "Ergotherapie",
    trainings: "Formations",
    administrativeSteps: "Démarches administratives",
    legalAid: "Aide juridique",
    familyMeditation: "Méditation familiale",
    individualPsychologicalSupport: "Soutien psychologique individuel",
    discussionGroups: "Groupes de parole",
    expertCommunity: "Communauté d'experts",
    peerHelp: "Pair-aidance",
    counseling: "Counseling",
    socioEsthetics: "Socio-esthétique",
    smallService: "Petit service",
    homeVisit: "Visite à domicile",
    walksAndOutings: "Promenades et sorties",
    telephoneSupport: "Soutien téléphonique",
    digitalAssistance: "Aide au numérique",
    solidarityTransport: "Transport solidaire",
    shoppingAssistance: "Aide aux courses",
    contact: "Contact",
    name: "Nom",
    email: "Email",
    message: "Message",
    namePlaceholder: "Entrez votre nom",
    emailPlaceholder: "Entrez votre adresse email",
    messagePlaceholder: "Rédigez votre message",
    soss: "Soss",
    sossAddress: "45, Avenue Grande-Duchesse Charlotte, L-3441 Dudelange, Luxembourg",
    sossNumber: "+352 621 611 444",
    sossEmail: "contact@soss.lu",
    send: "Envoyer",
    helpAndCareDifferently: "Aider et soigner autrement",
    logoMinistereLuxembourgAlt: "Logotype du ministère du Luxembourg",
    logoCopasAlt: "Logo Copas",
    logoCnsAlt: "Logo CNS",
    copyright: "© Website designed and developed by Guillaume Louis and Jordan Louis",
    nadia: "Nadia",
    medina: "Medina",
    mersida: "Mersida",
    michele: "Michèle",
    fatima: "Fatima",
    director: "Directrice",
    nurseManager: "Cadre infirmière",
    caregiverTrainee: "Aide soignante en formation",
    socialAndFamilialHelper: "Aide socio-familiale",
    occupationalTherapist: "Ergothérapeute",
    nurse: "Infirmière",
    emailSuccessfullySent: "Votre mail a bien été envoyé",
    emailError: "Une erreur est survenue lors de l'envoi de votre mail",
    childCare: "Garde d'enfants",
    dependencyInsurance: "Assurance dépendance",
    userRights: "Droit des usagers",
    menu: "Menu",
    nurseCoordinator: "Infirmière coordinatrice",
    photoNadia: "Photo Nadia",
    photoMedina: "Photo Medina",
    photoMersida: "Photo Mersida",
    photoMichele: "Photo Michele",
    photoFatima: "Photo Fatima",
    image1: "Image 1",
    image2: "Image 2",
    sossMap: "Carte de localisation de Soss"
  }
}
