import { HiNewspaper } from "react-icons/hi2";
import { FaHome, FaPeopleArrows, FaCarSide, FaBalanceScale, FaHandHoldingMedical, FaUtensils, FaHands, FaHandsHelping, FaMugHot, FaShoppingCart, FaBaby } from "react-icons/fa";
import { GiHealthNormal } from "react-icons/gi";
import { IoConstruct, IoDocumentText } from "react-icons/io5";
import { ReactComponent as WalkingTogether } from "../assets/icons/walking-together.svg"
import { ReactComponent as Vacuum } from "../assets/icons/vacuum.svg"
import { ReactComponent as AssistWalk } from "../assets/icons/assist-walk.svg"
import { ReactComponent as HealthHeart } from "../assets/icons/health-heart.svg"
import { ReactComponent as Massage } from "../assets/icons/massage.svg"
import { ReactComponent as HandHand } from "../assets/icons/hand-hand.svg"
import { ReactComponent as Psychology } from "../assets/icons/psychology.svg"
import { ReactComponent as Experts } from "../assets/icons/experts.svg"

const services = [
  {
    icon: <FaHome />,
    titleKey: "main.homeSupport",
    descriptionKey: "main.homeSupportDescription",
    services: [
      { icon: <AssistWalk width="2rem" />, key: "main.autonomyAssistance" },
      { icon: <WalkingTogether width="2.1rem" />, key: "main.accompanyingOuting" },
      { icon: <FaCarSide size="1.8rem" />, key: "main.travelAssistance" },
      { icon: <Vacuum width="2.1rem" />, key: "main.housingMaintenance" },
      { icon: <FaUtensils size="1.8rem" />, key: "main.mealDelivery" }
      // { icon: <IoConstruct size="2rem" />, key: "main.everydayServices" },
    ],
  },
  {
    icon: <GiHealthNormal />,
    titleKey: "main.homeCare",
    descriptionKey: "main.homeCareDescription",
    services: [
      { icon: <HealthHeart width="2rem" />, key: "main.nursingCare" },
      { icon: <Massage width="2.3rem" />, key: "main.kinesitherapy" },
      { icon: <HandHand width="2rem" />, key: "main.ergotherapy" }
    ]
  },
  {
    icon: <HiNewspaper />,
    titleKey: "main.information",
    descriptionKey: "main.informationDescription",
    services: [
      { icon: <IoDocumentText size="1.8rem" />, key: "main.administrativeSteps" },
      { icon: <FaHandHoldingMedical size="1.8rem" />, key: "main.dependencyInsurance" },
      { icon: <FaBalanceScale size="1.8rem" />, key: "main.userRights" },
      // { icon: <School width="2rem" />, key: "main.trainings" },
      // { icon: <FaBalanceScale size="1.8rem" />, key: "main.legalAid" },
      // { icon: <Family width="2rem" />, key: "main.familyMeditation" }
    ]
  },
  {
    icon: <FaHands />,
    titleKey: "main.caregiverSupport",
    descriptionKey: "main.caregiverSupportDescription",
    services: [
      { icon: <Psychology width="2rem" />, key: "main.individualPsychologicalSupport" },
      { icon: <Experts width="3.5rem" />, key: "main.expertCommunity" },
      { icon: <FaHandsHelping size="2rem" />, key: "main.peerHelp" },
      // { icon: <Discussion width="2.7rem" />, key: "main.discussionGroups" },
      // { icon: <Meeting width="2rem" />, key: "main.counseling" },
      // { icon: <Mirror width="1.8rem" />, key: "main.socioEsthetics" },
    ]
  },
  {
    icon: <FaPeopleArrows />,
    titleKey: "main.mutualAid",
    descriptionKey: "main.mutualAidDescription",
    services: [
      { icon: <IoConstruct size="2rem" />, key: "main.smallService" },
      { icon: <FaMugHot size="1.5rem" />, key: "main.homeVisit" },
      { icon: <FaCarSide size="1.8rem" />, key: "main.solidarityTransport" },
      { icon: <FaShoppingCart size="1.5rem" />, key: "main.shoppingAssistance" },
      { icon: <FaBaby size="1.5rem" />, key: "main.childCare" },
      // { icon: <WalkingTogether width="2.1rem" />, key: "main.walksAndOutings" },
      // { icon: <ImPhone size="1.5rem" />, key: "main.telephoneSupport" },
      // { icon: <BsDisplay size="1.8rem" width="1.8rem" />, key: "main.digitalAssistance" },
    ]
  }
];

export default services;