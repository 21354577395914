import { MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import App from "./App";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { FR_TRANSLATIONS } from "./translations/fr";
import { CustomFonts } from "./CustomFonts";

function Root() {

  i18n
    // .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      detection: {
        order: ["navigator"], // detect language from browser
      },
      resources: {
        fr: { translation: FR_TRANSLATIONS },
        // add other resources here
      },
      fallbackLng: "fr",
      debug: process.env.REACT_APP_ENV_NAME === "local",
    });

  // temporary freeze language to french
  i18n.changeLanguage("fr");

  return (
    <MantineProvider
      withGlobalStyles
      withNormalizeCSS
      withCSSVariables
      theme={{
        colors: {
          soss: [
            "#A8D6D2", "#93CFC9", "#7FC9C2", "#6BC4BC", "#58C1B7", "#45BFB3", "#3AB7AB", "#39A59B", "#37958C", "#35867E",
          ],
          secondary: ["#4A96A7", "#2B93C3"],
          neutral: ["#182F2D", "#15423E", "#6A8784", "#BDCBCA", "#E8EDED"]
        },
        primaryColor: "soss",
        fontFamily: "Now",
        headings: {
          fontFamily: "BalooDa2",
          fontWeight: 800,
          sizes: {
            h1: { fontSize: "3rem", lineHeight: "3rem" },
            h2: { fontSize: "2.5rem", lineHeight: "2.5rem" },
            h3: { fontSize: "2rem", lineHeight: "2rem" },
            h4: { fontSize: "1.5rem", lineHeight: "1.5rem" },
          },
        },
        components: {
          Button: {
            styles: {
              root: {
                transition: "background 0.2s",
              },
              inner: {
                fontFamily: "BalooDa2"
              }
            }
          }
        },
        globalStyles: theme => ({
          ":root": {
            [theme.fn.smallerThan(599)]: {
              fontSize: "15px"
            },
            [`@media (min-width: 600px) and (max-width: 904px)`]: {
              fontSize: "16px"
            },
            [`@media (min-width: 905px) and (max-width: 1239px)`]: {
              fontSize: "18px"
            },
            [theme.fn.largerThan(1240)]: {
              fontSize: "19px"
            }
          },
          "h1, h2, h3, h4, h5, h6": {
            color: `${theme.colors.neutral[0]}`,
          },
          ".mantine-Grid-col": {
            padding: 0
          }
        })
      }}
    >
      <Notifications />
      <CustomFonts />
      <App />
    </MantineProvider>
  )
}

export default Root;