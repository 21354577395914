import { Paper, Stack, Title, Text, rem, createStyles } from "@mantine/core";
import { useTranslation } from "react-i18next";

function Card({ image, title, descriptionKey }) {
  const { t } = useTranslation();
  const { classes } = useStyles();

  return (
    <Paper
      p="xl" pt={50} radius="xl" className={classes.card}
      sx={{
        background: `linear-gradient(360deg, rgba(0, 0, 0, 0.3) 26.76%, rgba(0, 0, 0, 0) 40.03%), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${image})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        flexShrink: 0,
      }}
    >
      <Stack justify="space-between" sx={{ height: "100%" }} pb="sm">
        <Title order={3} className={classes.title}>
          {title}
        </Title>
        <Text color="white" fz="lg" mt="auto">
          {t(descriptionKey)}
        </Text>
      </Stack>
    </Paper>
  );
};

const useStyles = createStyles((theme) => ({
  card: {
    height: 700,
    [theme.fn.smallerThan(749)]: {
      height: 400,
    },
    [`@media (min-width: 750px) and (max-width: 904px)`]: {
      height: 500,
    },
    [`@media (min-width: 905px) and (max-width: 1239px)`]: {
      height: 600,
    },
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  title: {
    fontWeight: 900,
    color: theme.white,
    fontSize: rem(60),
    lineHeight: rem(60)
  }
}));

export default Card;