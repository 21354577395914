// @ts-nocheck
import { Anchor, Badge, Burger, ActionIcon, Text, Image, Button, Drawer, Group, createStyles, Title, Stack, Flex, Paper, Timeline, Box, Accordion, Center, ThemeIcon, useMantineTheme, TextInput, Textarea, AspectRatio, Space } from "@mantine/core";
import { useScrollIntoView, useMediaQuery } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import { Trans, useTranslation } from "react-i18next";
import { HiPlus } from "react-icons/hi2";
import { ImPhone } from "react-icons/im";
import { BsCheck } from "react-icons/bs";
import { ReactComponent as BgWelcome } from "./assets/images/bg-welcome.svg"
import { ReactComponent as BgOurTeam } from "./assets/images/bg-our-team.svg"
import { ReactComponent as BgOurPhilosophy } from "./assets/images/bg-our-philosophy.svg"
import { ReactComponent as BgDottedLine } from "./assets/images/bg-dotted-line.svg"
import { ReactComponent as BgBand } from "./assets/icons/bg-band.svg"
import { ReactComponent as BgHeart } from "./assets/icons/bg-heart.svg"
import { ReactComponent as BgBand2 } from "./assets/icons/bg-band-2.svg"
import { ReactComponent as BgMedic } from "./assets/icons/bg-medic.svg"
import { ReactComponent as BgHeartMonitor } from "./assets/icons/bg-heart-monitor.svg"
import { ReactComponent as BgStar } from "./assets/icons/bg-star.svg"
import { ReactComponent as BgHeartCard } from "./assets/icons/bg-heart-card.svg"
import { ReactComponent as BgSyringe } from "./assets/icons/bg-syringe.svg"
import { ReactComponent as BgMedic2 } from "./assets/icons/bg-medic-2.svg"
import { ReactComponent as BgLungs } from "./assets/icons/bg-lungs.svg"
import { ReactComponent as BgDisabled } from "./assets/icons/bg-disabled.svg"
import { useEffect, useMemo, useState } from "react";
import { useRef } from "react";
import Card from "./components/Card.js";
import services from "./data/services.js";
import { register } from "swiper/element/bundle";
register();

function getImage(imageName) {
  return `https://d3q4ipdsu08pet.cloudfront.net/images/${imageName}`;
}

const carouselItems = [
  { image: getImage("carousel-1.webp"), title: "V", descriptionKey: "main.vipsVDescription" },
  { image: getImage("carousel-2.webp"), title: "I", descriptionKey: "main.vipsIDescription" },
  { image: getImage("carousel-3.webp"), title: "P", descriptionKey: "main.vipsPDescription" },
  { image: getImage("carousel-4.webp"), title: "S", descriptionKey: "main.vipsSDescription" },
];

let lastScrollTop = null;
let holdScrollEvent = false;

const images = [
  BgWelcome,
  BgOurTeam,
  BgOurPhilosophy,
  BgDottedLine,
  BgBand,
  BgHeart,
  BgBand2,
  BgMedic,
  BgHeartMonitor,
  BgStar,
  BgHeartCard,
  BgSyringe,
  BgMedic2,
  BgLungs,
  BgDisabled
];
// const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

function App() {
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width: 599px)");
  const isTablet = useMediaQuery("(max-width: 904px)");
  const isMedium = useMediaQuery("(max-width: 1239px)");
  const isSmall = useMediaQuery("(max-width: 750px)");
  const navBarHeight = useMemo(() => isSmall ? 80 : isTablet ? 100 : 120, [isTablet, isSmall]);
  const { classes } = useStyles(navBarHeight);
  const { classes: accordionClasses } = useAccordionStyles();
  const theme = useMantineTheme();
  const navBar = useRef(null);

  const [senderName, setSenderName] = useState("");
  const [senderEmail, setSenderEmail] = useState("");
  const [message, setMessage] = useState("");
  const [emailLoading, setEmailLoading] = useState(false);
  const [drawerIsOpened, setDrawerIsOpened] = useState(false);

  const { scrollIntoView: scrollToOurStory, targetRef: ourStoryContainer } = useScrollIntoView({ offset: isSmall ? navBarHeight + 60 : 60, onScrollFinish });
  const { scrollIntoView: scrollToOurServices, targetRef: ourServicesContainer } = useScrollIntoView({ offset: isSmall ? navBarHeight : 0, onScrollFinish });
  const { scrollIntoView: scrollToContact, targetRef: contactContainer } = useScrollIntoView({ offset: isSmall ? navBarHeight : 0, onScrollFinish });

  function onScrollFinish() {
    setTimeout(() => {
      holdScrollEvent = false;
    }, 100);
  }

  function onScrollStart() {
    setDrawerIsOpened(false);
    holdScrollEvent = true;
    if (!isSmall)
      navBar.current.style.top = `-${navBarHeight}px`;
  }

  function preload() {
    for (var i = 0; i < arguments.length; i++) {
      images[i] = new Image();
      images[i].src = preload.arguments[i];
    }
  }

  useEffect(() => {
    preload();
    const scrollHandler = () => {
      if (!holdScrollEvent && !isSmall) {
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        if (scrollTop > lastScrollTop && lastScrollTop > 0) {
          navBar.current.style.top = `-${navBarHeight}px`;
        }
        else if (lastScrollTop + window.innerHeight < document.documentElement.scrollHeight) {
          navBar.current.style.top = "0";
        }
        lastScrollTop = scrollTop;
      }
    }
    if (navBar.current) {
      navBar.current.style.top = 0;
      window.addEventListener("scroll", scrollHandler);
    }
    return () => window.removeEventListener("scroll", scrollHandler);
  }, [navBarHeight, isSmall]);


  function handleSendEmail() {
    if (senderName && senderEmail && message) {
      setEmailLoading(true);
      fetch("https://sjsaqcw291.execute-api.eu-west-3.amazonaws.com/send-email", {
        mode: "no-cors",
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          senderName, senderEmail, message, date: new Date()
        })
      }).then(res => {
        if (res) {
          notifications.show({
            title: t("main.contact"),
            message: t("main.emailSuccessfullySent"),
            icon: <ThemeIcon color="primary" radius="xl" size="lg"><BsCheck /></ThemeIcon>,
            withCloseButton: false,
            radius: "lg"
          });
          setSenderName("");
          setSenderEmail("");
          setMessage("");
        }
      }).catch(() => {
        notifications.show({
          title: t("main.contact"),
          message: t("main.emailError"),
          icon: <ThemeIcon color="red" radius="xl" sx={{ transform: "rotate(45deg)" }}><HiPlus /></ThemeIcon>,
          withCloseButton: false,
          color: "red"
        })
      }).finally(() => setEmailLoading(false))
    }
  }

  function _renderNavLinks() {
    return <>
      <Anchor className={classes.navAnchor} variant="outline" onClick={() => { onScrollStart(); scrollToOurStory() }}>{t("nav.aboutUs")}</Anchor>
      <Anchor className={classes.navAnchor} variant="outline" onClick={() => { onScrollStart(); scrollToOurServices() }}>{t("nav.ourServices")}</Anchor>
      <Anchor className={classes.navAnchor} variant="outline" onClick={() => { onScrollStart(); scrollToContact() }}>{t("nav.contact")}</Anchor>
    </>
  }

  function _renderNadiaImage() {
    return <Box className={classes.teamImg}>
      <img src={getImage("photo-nadia.webp")} alt={t("main.photoNadia")} loading="lazy" />
      <Stack className={classes.teamImgDescription} spacing={0}>
        <Title order={4} align="center" color="neutral.1" weight={500} transform="uppercase">{t("main.nadia")}</Title>
        <Text color="neutral.2" left="0" right="0" align="center">{t("main.director")}</Text>
        <Text color="neutral.2" left="0" right="0" align="center">{t("main.nurseManager")}</Text>
      </Stack>
    </Box>
  }

  function _renderMedinaImage() {
    return <Box className={classes.teamImg}>
      <img src={getImage("photo-medina.webp")} alt={t("main.photoMedina")} loading="lazy" />
      <Stack className={classes.teamImgDescription} spacing={0}>
        <Title order={4} align="center" color="neutral.1" weight={500} transform="uppercase">{t("main.medina")}</Title>
        <Text color="neutral.2" left="0" right="0" align="center">{t("main.socialAndFamilialHelper")}</Text>
      </Stack>
    </Box>
  }

  function _renderMersidaImage() {
    return <Box className={classes.teamImg}>
      <img src={getImage("photo-mersida.webp")} alt={t("main.photoMersida")} loading="lazy" />
      <Stack className={classes.teamImgDescription} spacing={0}>
        <Title order={4} align="center" color="neutral.1" weight={500} transform="uppercase">{t("main.mersida")}</Title>
        <Text color="neutral.2" left="0" right="0" align="center">{t("main.caregiverTrainee")}</Text>
      </Stack>
    </Box>
  }

  function _renderMicheleImage() {
    return <Box className={classes.teamImg}>
      <img src={getImage("photo-michele.webp")} alt={t("main.photoMichele")} loading="lazy" />
      <Stack className={classes.teamImgDescription} spacing={0}>
        <Title order={4} align="center" color="neutral.1" weight={500} transform="uppercase">{t("main.michele")}</Title>
        <Text color="neutral.2" left="0" right="0" align="center">{t("main.occupationalTherapist")}</Text>
      </Stack>
    </Box>
  }

  return (
    <Box sx={{ display: "flex", justifyContent: "center", overflow: "hidden" }}>
      <Box className={classes.container}>

        {/* navbar */}
        <Flex ref={navBar} className={classes.navBar} h={`${navBarHeight}px`}>
          <Group position="apart" my="auto" w="100%" className={classes.fullWidth}>
            {
              isSmall
                ? <>
                  <Burger opened={drawerIsOpened} onClick={setDrawerIsOpened} />
                  <img src={getImage("logo.webp")} alt={t("nav.logoAlt")} width={90} style={{ margin: 0, marginLeft: -20 }} loading="lazy" />
                  <Drawer
                    title={t("main.menu")} opened={drawerIsOpened}
                    overlayProps={{ opacity: 0.5, blur: 4 }}
                    closeButtonProps={{ size: "md" }}
                    onClose={() => setDrawerIsOpened(false)}
                    size={isMobile ? "50%" : "30%"}
                    styles={{ title: { fontFamily: "BalooDa2", fontWeight: "bold", color: theme.colors.neutral[1], fontSize: theme.fontSizes.xl } }}
                  >
                    <Stack>
                      {_renderNavLinks()}
                    </Stack>
                  </Drawer>
                </>
                : <>
                  <img src={getImage("logo.webp")} alt={t("nav.logoAlt")} loading="lazy" />
                  {_renderNavLinks()}
                </>
            }


            <Anchor href={`tel:${t("main.sossNumber")}`}>
              {isSmall
                ? <ActionIcon color="primary"><ImPhone size={30} /></ActionIcon>
                : <Button leftIcon={<ImPhone />} color="neutral.1"
                  variant="outline" className={classes.callButton} radius="xl">{t("main.sossNumber")}</Button>
              }
            </Anchor>
          </Group>
        </Flex>

        {/* welcome */}
        <BgWelcome className={classes.welcomeBg} />
        <Stack className={classes.welcomeContainer}>
          {!isSmall && <>
            <BgBand width="4.5rem" height="4.5rem" style={{ position: "absolute", top: -100, left: "33%" }} />
            <BgMedic width="4.5rem" height="4.5rem" style={{ position: "absolute", top: "250%", left: "10%" }} />
          </>}
          <Title order={isSmall ? 2 : 1} color="neutral.1">{t("main.homeHelpAndCare")}</Title>
          <Text size={isMobile ? 25 : 32} color="neutral.2" weight={500} lh={1}>
            {<Trans
              i18nKey="main.stayingAtHomeIsToRemainOneself"
              components={{ blueText: <Text color="soss" sx={{ display: "inline" }} /> }}
            />}
          </Text>
        </Stack>

        <Box sx={{ gridColumn: isMobile ? "3 / -1" : "5 / -1", gridRow: "1 / 2", position: "relative" }}>
          {!isSmall && <BgHeart width="4.5rem" height="4.5rem" style={{ position: "absolute", top: 10, left: "80%" }} />}

          <img src={getImage("image-1.webp")} width="100%" alt={t("main.image1")} style={{ aspectRatio: "320 / 309" }} loading="lazy" />
        </Box>

        <Space h={isMobile ? 50 : isSmall ? 100 : 150} sx={{ gridRow: "2 / 3" }} />

        {/* our story */}
        <Box className={classes.ourStoryImg}>
          {!isSmall && <BgHeartMonitor width="4.5rem" height="4.5rem" style={{ position: "absolute", top: "90%", left: "10%" }} />}
          <img width="100%" src={isSmall ? getImage("image-3.webp") : getImage("image-2.webp")} pos="relative" alt={t("main.image2")} loading="lazy" />
        </Box>
        <Paper ref={ourStoryContainer} p={isMobile ? 30 : 60} radius="xl" pos="relative" className={classes.ourStory}>
          {!isSmall && <BgBand2 width={isMedium ? "3rem" : "4rem"} height={isMedium ? "3rem" : "4rem"} style={{ position: "absolute", top: "-3%", left: "95%" }} />}
          <Stack>
            <Title order={isSmall ? 2 : 1} color="white">{t("main.ourStory")}</Title>
            <Text color="white"><Trans i18nKey="main.ourStoryText" /></Text>
          </Stack>
        </Paper>

        <Space h={isMobile ? 50 : isSmall ? 150 : 200} sx={{ gridRow: "5 / 6" }} />


        {/* our team */}
        <Box sx={{ position: "relative", gridColumn: isMobile ? "1 / -1" : "1 / 8", gridRow: "6 / 7", alignSelf: "start", marginBottom: 40 }}>
          {!isSmall && <BgStar width="4rem" height="4rem" style={{ position: "absolute", top: -50, left: "80%" }} />}
          <Title order={isSmall ? 2 : 1} color="neutral.1">{t("main.ourTeam")}</Title>
        </Box>
        <Box sx={{ position: "relative", gridColumn: isSmall ? "1 / -1" : "1 / -4", gridRow: "8 / 9", alignSelf: "start" }}>
          <Text color="neutral.2">
            {<Trans
              i18nKey="main.ourTeamDescription"
              components={{ blueText: <Text color="soss" sx={{ display: "inline", fontWeight: 500 }} /> }}
            />}
          </Text>
        </Box>

        {isSmall
          ? <Flex justify="center" gap="sm" wrap="wrap" maw="100%" mt={50} mb={30} sx={{ gridColumn: "1 / -1", gridRow: "9 / 10", "&>*": { flexShrink: 0 } }}>
            <Badge color="gray" size="lg"><Trans i18nKey="main.confidence" components={{ blueText: <Text color="soss" sx={{ display: "inline" }} /> }} /></Badge>
            <Badge color="gray" size="lg"><Trans i18nKey="main.skills" components={{ blueText: <Text color="soss" sx={{ display: "inline" }} /> }} /></Badge>
            <Badge color="gray" size="lg"><Trans i18nKey="main.compassion" components={{ blueText: <Text color="soss" sx={{ display: "inline" }} /> }} /></Badge>
            <Badge color="gray" size="lg"><Trans i18nKey="main.climate" components={{ blueText: <Text color="soss" sx={{ display: "inline" }} /> }} /></Badge>
            <Badge color="gray" size="lg"><Trans i18nKey="main.congruence" components={{ blueText: <Text color="soss" sx={{ display: "inline" }} /> }} /></Badge>
            <Badge color="gray" size="lg"><Trans i18nKey="main.cohesion" components={{ blueText: <Text color="soss" sx={{ display: "inline" }} /> }} /></Badge>
            <Badge color="gray" size="lg"><Trans i18nKey="main.constance" components={{ blueText: <Text color="soss" sx={{ display: "inline" }} /> }} /></Badge>
          </Flex>
          : <Timeline active={7} lineWidth={3} align="right" className={classes.ourTeam7c} styles={{ itemTitle: { position: "relative", fontWeight: 500, fontSize: "1.5rem", bottom: 4 } }}>
            <Timeline.Item title={<Trans i18nKey="main.confidence" components={{ blueText: <Text color="soss" sx={{ display: "inline" }} /> }} />} />
            <Timeline.Item title={<Trans i18nKey="main.skills" components={{ blueText: <Text color="soss" sx={{ display: "inline" }} /> }} />} />
            <Timeline.Item title={<Trans i18nKey="main.compassion" components={{ blueText: <Text color="soss" sx={{ display: "inline" }} /> }} />} />
            <Timeline.Item title={<Trans i18nKey="main.climate" components={{ blueText: <Text color="soss" sx={{ display: "inline" }} /> }} />} />
            <Timeline.Item title={<Trans i18nKey="main.congruence" components={{ blueText: <Text color="soss" sx={{ display: "inline" }} /> }} />} />
            <Timeline.Item title={<Trans i18nKey="main.cohesion" components={{ blueText: <Text color="soss" sx={{ display: "inline" }} /> }} />} />
            <Timeline.Item title={<Trans i18nKey="main.constance" components={{ blueText: <Text color="soss" sx={{ display: "inline" }} /> }} />} />
          </Timeline>}

        <Flex pos="relative" sx={{
          gridColumn: "1 / -1", gridRow: "10 / 11",
        }}>
          <BgOurTeam className={classes.bgOurTeam} />
          {isSmall
            ? <Flex mx={isMobile ? -16 : -32} px={isMobile ? 16 : 32} gap="sm" sx={{ position: "relative", overflow: "auto", right: 0 }}>
              {_renderNadiaImage()}
              {_renderMedinaImage()}
              {_renderMersidaImage()}
              {_renderMicheleImage()}
            </Flex>
            : <Stack className={classes.teamImgContainer} align="start">
              <BgHeartCard width="4.5rem" height="4.5rem" style={{ position: "absolute", top: 0, left: -10 }} />
              <BgSyringe width="4.5rem" height="4.5rem" style={{ position: "absolute", top: "40%", left: "70%" }} />
              <BgMedic2 width="4.5rem" height="4.5rem" style={{ position: "absolute", top: "95%", left: "10%" }} />
              <Flex gap="5vw">
                {_renderNadiaImage()}
                {_renderMedinaImage()}
              </Flex>
              <Flex gap="5vw" ml={isMedium ? 170 : "auto"} mt={-50}>
                {_renderMersidaImage()}
                {_renderMicheleImage()}
              </Flex>
            </Stack>}
        </Flex>

        <Space h="100px" sx={{ gridRow: "11 / 12" }} />

        {/* our philosophy */}
        <Box className={classes.ourPhilosophyContainer} sx={{ gridColumn: "1 / -1", gridRow: "12 / 13" }}>
          <BgOurPhilosophy className={classes.bgOurPhilosophy} />
          <Stack spacing={40} pos="relative" py={60}>
            <Title order={isSmall ? 2 : 1} color="neutral.1">{t("main.ourPhilosophy")}</Title>

            <Text color="neutral.1">
              {<Trans
                i18nKey="main.ourPhilosophyDescription"
                components={{ blueText: <Text color="secondary.1" sx={{ display: "inline", fontWeight: "bold" }} /> }}
              />}
            </Text>

            <div>
              <swiper-container
                // style={{ filter: isSafari ? "none" : "drop-shadow(0px 4px 44px rgba(88, 108, 106, 0.39))" }}
                slides-per-view={isMobile ? "1" : "2"} navigation="true"
                pagination="true" slides-per-group={isMobile ? "1" : "2"} space-between="20"
                pagination-clickable="true">
                {carouselItems.map((ce, i) => <swiper-slide key={i}>
                  <Card {...ce} />
                </swiper-slide>)}
              </swiper-container>
            </div>
          </Stack>
        </Box>

        <Stack py={60} ref={ourServicesContainer} sx={{ gridColumn: "1 / -1", gridRow: "13 / 14" }}>
          <Title order={isSmall ? 2 : 1} color="neutral.1">{t("main.ourServices")}</Title>
          <Accordion
            radius="lg"
            variant="filled"
            defaultValue="customization"
            classNames={accordionClasses}
            chevron={<HiPlus size="1.8rem" />}
          >
            {
              services.map((item, i) => (
                <Accordion.Item value={item.titleKey} key={i}>
                  <Accordion.Control px="lg" py="xs">
                    <Flex gap={"md"} align="center">
                      <ThemeIcon radius="xl" size="3rem" color="neutral.1" sx={{ "&>*": { fontSize: "1.8rem" } }} >
                        {item.icon}
                      </ThemeIcon>
                      <Stack spacing={0}>
                        <Text fz="xl" weight={500} color="neutral.1">{t(item.titleKey)}</Text>
                        <Text color="neutral.2">{t(item.descriptionKey)}</Text>
                      </Stack>
                    </Flex>
                  </Accordion.Control>
                  <Accordion.Panel>
                    <Group pl={isMobile ? 0 : `calc((${theme.spacing.md} + 3rem))`} position={isMobile ? "center" : "start"}>
                      {item.services.map((service, i) => (
                        <Box h={150} key={i} sx={{ aspectRatio: "88 / 100", backgroundImage: `url(${getImage("hexagon.webp")})`, backgroundSize: "cover" }}>
                          <Center h="100%">
                            <Stack align="center" spacing={6} p="xs" c="secondary.0">
                              {service.icon}
                              <Text size="sm" align="center">{t(service.key)}</Text>
                            </Stack>
                          </Center>
                        </Box>
                      ))}
                    </Group>
                  </Accordion.Panel>
                </Accordion.Item>
              ))
            }
          </Accordion>
        </Stack>

        <Center className={classes.bgContact} sx={{ gridColumn: "1 / -1", gridRow: "14 / 15" }} py={60} px={isSmall ? 0 : 60} ref={contactContainer}>
          {!isSmall && <>
            <BgDottedLine className={classes.bgDottedLine} />
            <BgDisabled width="3rem" height="3rem" style={{ position: "absolute", top: "80%", left: "95%", zIndex: 3 }} />
          </>}
          <Stack sx={{ zIndex: 2 }} spacing={60} w="100%">
            <Title order={isSmall ? 2 : 1} color="white" align="center">{t("main.contact")}</Title>
            <Paper radius="xl" p="xl" pos="relative" >
              {!isSmall && <BgLungs width="4.5rem" height="4.5rem" style={{ position: "absolute", top: "-6%", left: "-5%", zIndex: 3 }} />}
              <Flex align={isSmall ? "stretch" : "start"} gap="md" direction={isSmall ? "column" : "row"} >
                <Stack sx={{ flex: isSmall ? 0 : "300px 1 1" }}>
                  <AspectRatio ratio={(isSmall ? 1.5 : 1) / 1}>
                    <iframe title={t("main.sossMap")} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2592.164324034783!2d6.0846784!3d49.48140289999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479536fac31caa55%3A0x209e75e828a378e1!2s45%20Av.%20Grande-Duchesse%20Charlotte%2C%203441%20Dudelange%2C%20Luxembourg!5e0!3m2!1sen!2sfr!4v1683383089744!5m2!1sen!2sfr" style={{ border: 0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                  </AspectRatio>
                  <Text weight={500} color="neutral.1">{t("main.soss")}</Text>
                  <Text color="neutral.2">{t("main.sossAddress")}</Text>
                  <Text color="neutral.2">{t("main.sossNumber")}</Text>
                </Stack>
                <Stack sx={{ flex: "300px 1 1" }} spacing="xl">
                  <TextInput label={t("main.name")} size="md" placeholder={t("main.namePlaceholder")} value={senderName} onChange={(e) => setSenderName(e.currentTarget.value)} />
                  <TextInput label={t("main.email")} size="md" placeholder={t("main.emailPlaceholder")} value={senderEmail} onChange={(e) => setSenderEmail(e.currentTarget.value)} />
                  <Textarea minRows={4} maxRows={4} size="md" label={t("main.message")} placeholder={t("main.messagePlaceholder")} value={message} onChange={(e) => setMessage(e.currentTarget.value)} />
                  <Button loading={emailLoading} radius="xl" size="lg" sx={{ alignSelf: "flex-end" }} onClick={handleSendEmail}>{t("main.send")}</Button>
                </Stack>
              </Flex>
            </Paper>
          </Stack>
        </Center>

        <Stack py={60} className={classes.footer} sx={{ gridColumn: "1 / -1", gridRow: "15 / 16" }} spacing={60}>
          <Group align="flex-start">
            <Box sx={theme => ({ display: "grid", gridColumnGap: "10%", gridRowGap: theme.spacing.md, gridTemplateColumns: "1fr minmax(1fr, 300px)" })}>
              <img sx={{ gridColumn: 1, alignSelf: "end" }} src={getImage("logo.webp")} alt={t("nav.logoAlt")} width={isSmall ? 100 : 150} loading="lazy" />
              <Text sx={{ gridColumn: 1 }} color="neutral.2">{t("main.helpAndCareDifferently")}</Text>
              <Title sx={{ gridColumn: isSmall ? 1 : 2, gridRow: isSmall ? 2 : 1, alignSelf: "end" }} mt={isSmall ? 20 : 0} lh="17px" order={isSmall ? 4 : 3} color="soss" weight={500}>Contact</Title>
              <Text sx={{ gridColumn: isSmall ? 1 : 2 }} color="neutral.2">{t("main.sossAddress")}</Text>
              <Text sx={{ gridColumn: isSmall ? 1 : 2 }} color="neutral.2">{t("main.sossNumber")}</Text>
              <Text sx={{ gridColumn: isSmall ? 1 : 2 }} color="neutral.2">{t("main.sossEmail")}</Text>
            </Box>
          </Group>

          <Group position="apart" w="100%">
            <img src={getImage("logo-ministere-luxembourg.webp")} alt={t("main.logoMinistereLuxembourgAlt")} height={isSmall ? 60 : 90} width="auto" loading="lazy" />
            <img src={getImage("logo-copas.webp")} alt={t("main.logoCopasAlt")} height={isSmall ? 60 : 90} width="auto" loading="lazy" />
            <img src={getImage("logo-cns.webp")} alt={t("main.logoCnsAlt")} height={isSmall ? 60 : 90} width="auto" loading="lazy" />
          </Group>
        </Stack>

        <Box className={classes.copyright} sx={{ gridColumn: "1 / -1", gridRow: "16 / 17" }}>
          <Text p="md" color="white">{t("main.copyright")}</Text>
        </Box>
      </Box>
    </Box>
  );
};

const useAccordionStyles = createStyles((theme) => ({
  item: {
    position: "relative",
    zIndex: 0,
    transition: "transform 150ms ease",

    "&[data-active]": {
      transform: "scale(1.03)",
      boxShadow: theme.shadows.md,
      zIndex: 1
    },
  },
  content: {
    padding: theme.spacing.lg,
    paddingTop: 0
  },
  chevron: {
    color: theme.colors.neutral[0],
    "&[data-rotate]": {
      transform: "rotate(45deg)",
    },
  }
}))

const bgFullWidth = {
  content: "''",
  display: "block",
  position: "absolute",
  inset: 0,
  width: "100vw",
  marginLeft: "50%",
  transform: "translateX(-50%)",
  maxWidth: "100vw",
  zIndex: -1
}

const useStyles = createStyles((theme, navBarHeight) => ({
  container: {
    display: "grid",
    flexGrow: 1,
    paddingTop: navBarHeight + 30,
    gridTemplateColumns: "repeat(12, 1fr)",
    alignItems: "center",
    columnGap: 8,
    overflow: "visible",
    // rowGap: 100,
    [theme.fn.smallerThan(600)]: {
      marginLeft: 16,
      marginRight: 16,
      gridTemplateColumns: "repeat(4, 1fr)",
    },
    [`@media (min-width: 600px) and (max-width: 904px)`]: {
      marginLeft: 32,
      marginRight: 32,
      gridTemplateColumns: "repeat(8, 1fr)",
    },
    [`@media (min-width: 905px) and (max-width: 1239px)`]: {
      maxWidth: 1000,
      marginLeft: 32,
      marginRight: 32,
    },
    [theme.fn.largerThan(1240)]: {
      maxWidth: 1200,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  ourStory: {
    gridColumn: "-8 / -1",
    gridRow: "3 / 4",
    background: "linear-gradient(96.78deg, #0B7188 11.49%, #134C59 91.94%), #D9D9D9",
    [theme.fn.smallerThan(749)]: {
      gridColumn: "1 / -1",
    },
    [`@media (min-width: 750px) and (max-width: 905px)`]: {
      gridColumn: "-7 / -1",
    },
  },
  ourStoryImg: {
    position: "relative",
    zIndex: 1,
    gridColumn: "1 / -7",
    gridRow: "3 / 4",
    paddingRight: 30,
    [theme.fn.smallerThan(599)]: {
      gridColumn: "1 / -1",
      gridRow: "4 / 5",
      padding: 0,
      marginTop: 50,
    },
    [`@media (min-width: 600px) and (max-width: 749px)`]: {
      gridColumn: "2 / -2",
      gridRow: "4 / 5",
      padding: 0,
      marginTop: 100,
    },
    [`@media (min-width: 750px) and (max-width: 905px)`]: {
      gridColumn: "1 / 4",
      paddingRight: 50,
    },
  },
  welcomeContainer: {
    position: "relative",
    zIndex: 1,
    gridColumn: "1 / -1",
    gridRow: "1 / 2",
    marginBottom: "20%",
  },
  welcomeBg: {
    ...bgFullWidth,
    height: "auto",
    gridColumn: "1 / -1",
    gridRow: "1 / 2",
    top: 500,
    [theme.fn.smallerThan(599)]: {
      top: 250
    },
    [`@media (min-width: 600px) and (max-width: 905px)`]: {
      top: 300,
    },
  },
  navAnchor: {
    fontWeight: 500,
    color: theme.colors.neutral[0],
    fontFamily: "BalooDa2",
    transition: "color 200ms",
    "&:hover": {
      textDecoration: "none",
      color: theme.colors.soss
    }
  },
  callButton: {
    border: "2px solid #43BED4",
    boxShadow: "0px 0px 6px rgba(65, 193, 216, 0.61)",
    "&:hover": {
      backgroundColor: theme.fn.lighten("#43BED4", 0.5)
    }
  },
  teamImgContainer: {
    position: "relative",
    width: "100%",
    marginTop: -300,
    [theme.fn.smallerThan(905)]: {
      marginTop: -250,
    }
  },
  teamImg: {
    position: "relative",
    "&>img": {
      height: 540,
      [theme.fn.smallerThan(905)]: {
        height: 400
      },
    }
  },
  teamImgDescription: {
    position: "absolute",
    "&>*": {
      lineHeight: 1.4,
    },
    top: "77%",
    maxWidth: "70%",
    marginLeft: "auto",
    marginRight: "auto",
    left: 0,
    right: 0,
    fontSize: theme.fontSizes.sm
  },
  ourPhilosophyContainer: {
    position: "relative",
    "&:before": {
      ...bgFullWidth,
      background: "linear-gradient(247.78deg, #ACE8F1 -3.05%, #A5F5EA 108.06%)"
    }
  },
  fullWidth: {
    width: "100vw",
    [theme.fn.smallerThan(599)]: {
      marginLeft: 16,
      marginRight: 16
    },
    [`@media (min-width: 600px) and (max-width: 904px)`]: {
      marginLeft: 32,
      marginRight: 32,
    },
    [`@media (min-width: 905px) and (max-width: 1239px)`]: {
      maxWidth: 1000,
      marginLeft: 32,
      marginRight: 32,
    },
    [theme.fn.largerThan(1240)]: {
      maxWidth: 1200,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  navBar: {
    top: 0,
    left: 0,
    transition: "top 300ms 300ms cubic-bezier(0.4, 0, 0.2, 1)",
    zIndex: 10,
    backdropFilter: "blur(10px)",
    transformOrigin: "top center",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#ffffffd9",
    position: "fixed",
    width: "100vw",
    "& img": {
      marginBottom: 30,
      width: 130,
      [theme.fn.smallerThan(905)]: {
        width: 100,
      }
    }
  },
  bgOurTeam: {
    ...bgFullWidth
  },
  ourTeam7c: {
    gridColumn: "-4 / -1",
    gridRow: "8 / 9",
    alignSelf: "start",
    position: "relative",
    "&:before": {
      content: "'7C'",
      fontFamily: "BalooDa2",
      fontSize: 500,
      lineHeight: "400px",
      color: theme.colors.soss[7],
      fontWeight: "bold",
      opacity: 0.09,
      position: "absolute",
      left: "-160%",
      bottom: -50,
      [theme.fn.smallerThan(904)]: {
        left: -300,
        bottom: -100,
        fontSize: 400,
      },
      [`@media (min-width: 905px) and (max-width: 1239px)`]: {
        left: -500,
        bottom: -70
      },
    }
  },
  bgOurPhilosophy: {
    ...bgFullWidth,
    inset: 0,
    margin: "auto",
    transform: "none",
    height: "95%",
    width: "95%"
  },
  bgDottedLine: {
    ...bgFullWidth,
    zIndex: 1,
    marginTop: "50%",
    transform: "translate(-50%, -50%)"
    // position: "absolute",
    // width: "100vw",
    // inset: 0,
    // margin: "auto",
    // zIndex: 1
  },
  footer: {
    position: "relative",
    "&:before": {
      ...bgFullWidth,
      background: "#E9F4F6",
    }
  },
  bgContact: {
    position: "relative",
    "&:before": {
      ...bgFullWidth,
      background: `linear-gradient(0deg, rgba(21, 66, 62, 0.6), rgba(21, 66, 62, 0.6)), url(${getImage("contact.webp")})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "50%",
      backgroundSize: "cover",
      bottom: "50%",
      zIndex: 1
    },

    "&:after": {
      ...bgFullWidth,
      background: "#C6EEF6"
    }
  },
  copyright: {
    position: "relative",
    "&:before": {
      ...bgFullWidth,
      background: "#437A87",
    }
  }
}));

export default App;
